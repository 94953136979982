@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;700;900&display=swap);
/*
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*/

html {
  font-size: 10px;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  background-color: rgb(244,244,244);
  color: #333;
  font-family: 'Roboto', sans-serif;
}

p {
  font-size: 1.6rem;
}

small {
  font-size: 1.2rem;
}

.container {
  margin: 0 auto;
  max-width:1000px;
  padding: 40px;
}

.title {
  font-size: 4.4rem;
  text-align: center
}

.form {
  display:grid;
}

.label {
  font-size: 1.2rem;
  margin-bottom: 0.2rem;
  text-transform: uppercase;
}

.input {
  font-size: 1.6rem;
  padding: 0.5rem 2rem;
  line-height: 2.8rem;
  border-radius: 20px;
  border: 1px solid #ddd;
  margin-bottom: 1rem;
}

.button {
  background-color: rgba(0,0,0,0.75);
  color: white;
  padding: 1rem 2rem;
  border: 1px solid rgba(0,0,0,0.75);
  border-radius: 20px;
  font-size: 1.4rem;
  cursor: pointer;
  transition: background-color 250ms;
}

.button:hover {
  background-color: rgba(0,0,0,0.85);
}

.input:focus, .label:focus, .button:focus {
  border-radius: 20px;
  outline: none;
  box-shadow: 0px 0px 2px #0066ff;
}


@media (min-width:786px){
  .form {
      grid-template-columns: auto 1fr auto;
      grid-gap: 1rem;
      align-items: center;
  }
  
  .input {
      margin-bottom: 0;
  }
}


.card-list {
  margin-top: 4rem;
}

.card {
  padding: 2rem 4rem;
  border-radius: 10px;
  box-shadow: 1px 1px 5px rgba(0,0,0,0.25);
  margin-bottom: 2rem;
  background-color: white;
}

.card--title {
  margin-bottom: 0.5rem;
  font-size: 3.2rem;
}

.card--image {
  margin: 0 auto;
  display: block
}
